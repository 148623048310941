import {
  FETCH_RENTAL_FORM_V2_REQUEST,
  FETCH_RENTAL_FORM_V2_SUCCESS,
} from 'Constants/redux';
import service from '../../api/service';
import { ROOT_URL } from '../../api';
import {
  closeLoadingSpinner,
  openLoadingSpinner,
} from '../LoadingSpinnerActions';
import { ThunkAction } from 'redux-thunk';
import { RentalFormActionTypes } from './types';
import AppState from '../../types/AppState';

export const fetchRentalVersion = (
  id: number
): ThunkAction<
  Promise<void>,
  AppState,
  unknown,
  RentalFormActionTypes
> => (dispatch) => {
  dispatch(openLoadingSpinner('Fetching rental data...'));

  dispatch({
    type: FETCH_RENTAL_FORM_V2_REQUEST,
  });

  return service.get(`${ROOT_URL}/rental_versions/${id}`, {})
    .then((response) => {
      dispatch({
        type: FETCH_RENTAL_FORM_V2_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch(closeLoadingSpinner());
    });
};
