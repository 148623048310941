import { ROOT_URL, adminAuthToken, saveError } from 'Api';
import { history } from 'Components/Routes';
import {
  apiToClassNames,
  apiToSingularConnections,
} from 'Constants/displayConstants';
import {
  REFRESH_RENTAL,
  FETCH_RENTAL_REQUEST,
  FETCH_RENTAL_FAILURE,
  FETCH_RENTAL_SUCCESS,
  FETCH_RENTAL_PICKLIST_REQUEST,
  FETCH_RENTAL_PICKLIST_FAILURE,
  FETCH_RENTAL_PICKLIST_SUCCESS,
  FETCH_RENTAL_INFO_REQUEST,
  FETCH_RENTAL_INFO_FAILURE,
  FETCH_RENTAL_INFO_SUCCESS,
  UPDATE_RENTAL_REQUEST,
  UPDATE_RENTAL_FAILURE,
  UPDATE_RENTAL_SUCCESS,
  CANCEL_RENTAL_REQUEST,
  CANCEL_RENTAL_FAILURE,
  CANCEL_RENTAL_SUCCESS,
  DELETE_RENTAL_REQUEST,
  DELETE_RENTAL_FAILURE,
  DELETE_RENTAL_SUCCESS,
  RESERVE_RENTAL_REQUEST,
  RESERVE_RENTAL_FAILURE,
  RESERVE_RENTAL_SUCCESS,
  CLOSE_RENTAL_REQUEST,
  CLOSE_RENTAL_FAILURE,
  CLOSE_RENTAL_SUCCESS,
  CHECK_OUT_RENTAL_REQUEST,
  CHECK_OUT_RENTAL_FAILURE,
  CHECK_OUT_RENTAL_SUCCESS,
  CHECK_IN_RENTAL_REQUEST,
  CHECK_IN_RENTAL_FAILURE,
  CHECK_IN_RENTAL_SUCCESS,
  SET_CHECK_IN_SIGNATURE,
  SET_CHECK_OUT_SIGNATURE,
  SET_RENTAL_PROPS,
  REQUEST_RENTAL_REQUEST,
  REQUEST_RENTAL_SUCCESS,
  REQUEST_RENTAL_FAILURE,
  BULK_PICK_RENTAL_ITEMS_REQUEST,
  SERIALIZED_BULK_PICK_RENTAL_ITEMS_REQUEST,
  SERIALIZED_BULK_PICK_RENTAL_ITEMS_SUCCESS,
  SERIALIZED_BULK_PICK_RENTAL_ITEMS_FAILURE,
  BULK_PICK_RENTAL_ITEMS_SUCCESS,
  BULK_PICK_RENTAL_ITEMS_FAILURE,
  PICK_RENTAL_ITEM_REQUEST,
  PICK_RENTAL_ITEM_SUCCESS,
  PICK_RENTAL_ITEM_FAILURE,
  UNPICK_RENTAL_ITEM_REQUEST,
  UNPICK_RENTAL_ITEM_SUCCESS,
  UNPICK_RENTAL_ITEM_FAILURE,
  BULK_CHECK_IN_RENTAL_ITEMS_REQUEST,
  BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS,
  BULK_CHECK_IN_RENTAL_ITEMS_FAILURE,
  SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_REQUEST,
  SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS,
  SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_FAILURE,
  BULK_UNCHECK_IN_RENTAL_ITEMS_SUCCESS,
  BULK_UNCHECK_IN_RENTAL_ITEMS_REQUEST,
  BULK_UNCHECK_IN_RENTAL_ITEMS_FAILURE,
  CHECK_IN_RENTAL_ITEM_REQUEST,
  CHECK_IN_RENTAL_ITEM_SUCCESS,
  CHECK_IN_RENTAL_ITEM_FAILURE,
  UNCHECK_IN_RENTAL_ITEM_REQUEST,
  UNCHECK_IN_RENTAL_ITEM_FAILURE,
  REPORT_LACK_RENTAL_ITEM_REQUEST,
  REPORT_LACK_RENTAL_ITEM_SUCCESS,
  REPORT_LACK_RENTAL_ITEM_FAILURE,
  ADD_FEE_RENTAL_ITEM_REQUEST,
  ADD_FEE_RENTAL_ITEM_SUCCESS,
  ADD_FEE_RENTAL_ITEM_FAILURE,
  UPDATE_RENTAL_ITEM_REQUEST,
  UPDATE_RENTAL_ITEM_SUCCESS,
  UPDATE_RENTAL_ITEM_FAILURE,
  CONNECT_RENTAL_REQUEST,
  CONNECT_RENTAL_SUCCESS,
  CONNECT_RENTAL_FAILURE,
  SUBMIT_QUOTE_REQUEST,
  REQUEST_CHANGES_REQUEST,
  REQUEST_CANCEL_REQUEST,
  UPDATE_SUPPLIER_RENTAL_REQUEST,
  SET_SUBRENTAL_ITEM,
  ADD_RENTAL_UNREAD_MESSAGE,
  REPOSITION_INVENTORY_SUCCESS,
  OPEN_CIRCLE_OF_DEATH_ERROR_MODAL,
  VALIDATE_ADMIN_FAIL,
  SET_MANIPULATED_PICK_LIST_ITEMS,
  FETCH_DELIVERY_TICKETS_REQUEST_FAILURE,
  FETCH_DELIVERY_TICKETS_REQUEST_SUCCESS,
  FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_SUCCESS,
  FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_FAILURE,
  SET_SELECTED_DT_ID,
  CHECKOUT_DELIVERY_TICKET_REQUEST,
  CHECKOUT_DELIVERY_TICKET_SUCCESS,
  CHECKOUT_DELIVERY_TICKET_FAILURE,
  CHECKIN_DELIVERY_TICKET_REQUEST,
  CHECKIN_DELIVERY_TICKET_SUCCESS,
  CHECKIN_DELIVERY_TICKET_FAILURE,
  CLOSE_DELIVERY_TICKET_REQUEST,
  CLOSE_DELIVERY_TICKET_SUCCESS,
  CLOSE_DELIVERY_TICKET_FAILURE,
  SET_SELECTED_DELIVERY_TICKET_SUCCESS,
  SET_SELECTED_DELIVERY_TICKET_FAILURE,
  CREATE_RENTAL_SERVICE_ROUTES_FAILURE,
  CREATE_RENTAL_SERVICE_ROUTES_REQUEST,
  CREATE_RENTAL_SERVICE_ROUTES_SUCCESS,
} from 'Constants/redux';
import Endpoints from 'Api/endpoints';
import { standardService } from 'Api/service';
import service from 'Api/service';
import { setErrors, openSuccessSnackBar } from './dashboardActionCreators';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';
import converter from 'json-style-converter/es5';

export const fetchRental = (
  id,
  background = false,
  endpoint = 'showOne',
) => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_RENTAL_REQUEST,
      background,
    });

    return Endpoints.rentals[endpoint](id)
      .then((response) => {
        // handling corrupted rental
        if (
          response.data.rental.schedule == null ||
          response.data.rental.rentalSetting == null
        ) {
          dispatch(
            setErrors(
              `There is an issue with the rental "${response.data.rental.name}".  Please contact customer services.`,
              10
            )
          );

          const authTokenHead = adminAuthToken();

          if (authTokenHead) {
            standardService
              .get(`${ROOT_URL}/admin_auth/validate_token`, {
                uid: authTokenHead['uid'],
                client: authTokenHead['client'],
                'access-token': authTokenHead['access-token'],
              })
              .then((response) => {
                dispatch(closeLoadingSpinner());
                dispatch({ type: OPEN_CIRCLE_OF_DEATH_ERROR_MODAL });
              })
              .catch((error) => {
                saveError(error, getState());
                dispatch({ type: VALIDATE_ADMIN_FAIL });
              });
          }
        }
        dispatch({
          type: FETCH_RENTAL_SUCCESS,
          response,
        });

        return response;
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          type: FETCH_RENTAL_FAILURE,
          error,
        });
      })
      .finally(() => dispatch(closeLoadingSpinner()));
  };
};

export const fetchRentalPickList = (
  id,
  background = false,
  endpoint = 'getPickList'
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner());
    dispatch({
      type: FETCH_RENTAL_PICKLIST_REQUEST,
      background,
    });

    Endpoints.rentals[endpoint](id)
      .then((response) => {
        dispatch({
          type: FETCH_RENTAL_PICKLIST_SUCCESS,
          response,
        });
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          type: FETCH_RENTAL_PICKLIST_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const fetchRentalInfo = (id, infoName) => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_RENTAL_INFO_REQUEST,
    });

    Endpoints.rentals
      .showOneInfo(id, infoName)
      .then((response) => {
        dispatch({
          type: FETCH_RENTAL_INFO_SUCCESS,
          infoName,
          response,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          type: FETCH_RENTAL_INFO_FAILURE,
          error,
        });
      });
  };
};

function shouldFetchRental(state, id) {
  const { rental } = state;
  if (!rental) {
    return true;
  }

  if (rental.loading) {
    return false;
  }

  return rental.rental.id !== parseInt(id, 10) || rental.shouldRefresh;
}

export const refreshRental = () => ({
  type: REFRESH_RENTAL,
});

export const fetchRentalIfNeeded = (id, background = false, endpoint) => {
  return (dispatch, getState) => {
    if (shouldFetchRental(getState(), id)) {
      dispatch(openLoadingSpinner(''));
      return dispatch(fetchRental(id, background, endpoint));
    }
  };
};

export const cancelRental = (id, reason) => {
  return (dispatch, getState) => {
    dispatch({
      type: CANCEL_RENTAL_REQUEST,
    });
    dispatch(openLoadingSpinner('Cancelling the order...'));
    Endpoints.rentals
      .cancelOne(id, reason)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: CANCEL_RENTAL_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(setErrors(error.response.data));
        dispatch(closeLoadingSpinner());
        dispatch({
          type: CANCEL_RENTAL_FAILURE,
          error,
        });
      });
  };
};

export const deleteRental = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_RENTAL_REQUEST,
    });
    dispatch(openLoadingSpinner('Deleting the order...'));
    Endpoints.rentals
      .deleteOne(id)
      .then((response) => {
        window.location.href = '/orders/rentals/open';
        dispatch({
          type: DELETE_RENTAL_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          type: DELETE_RENTAL_FAILURE,
          error,
        });
      })
      .finally(() => {
        dispatch(closeLoadingSpinner());
      });
  };
};

const reserveRequestRental = () => ({
  type: RESERVE_RENTAL_REQUEST,
});

const reserveRentalSuccess = (rental) => ({
  type: RESERVE_RENTAL_SUCCESS,
  payload: {
    rental,
  },
});

const reserveRentalFailure = (error) => ({
  type: RESERVE_RENTAL_FAILURE,
  payload: error,
});

export const reserveRental = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Reserving order...'));
    dispatch(reserveRequestRental());

    Endpoints.rentals
      .reserveOne(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch(reserveRentalSuccess(response.data.rental));
        /**
         * We are reloading the whole window due to an issue when moving a rental to
         * reservation overbooking alerts/numbers are weird (TD1-1905)
         */
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response?.data));
        dispatch(reserveRentalFailure(error));
      });
  };
};

export const closeRental = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Closing order...'));
    dispatch({
      type: CLOSE_RENTAL_REQUEST,
    });

    Endpoints.rentals
      .closeOne(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: CLOSE_RENTAL_SUCCESS,
          response,
        });
        dispatch(fetchRentalPickList(response.data.rental.token));
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: CLOSE_RENTAL_FAILURE,
        });
      });
  };
};

export const closeDeliveryTicket = (rentalId, deliveryTicket) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Closing secondary order...'));
    dispatch({
      type: CLOSE_DELIVERY_TICKET_REQUEST,
    });
    service
      .post(
        `${ROOT_URL}/rentals/${rentalId}/delivery_tickets/${deliveryTicket.id}/close`
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          response: response.data,
          type: CLOSE_DELIVERY_TICKET_SUCCESS,
        });
        dispatch(fetchRentalPickList(response.data.rental.token));
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response));
        dispatch({
          type: CLOSE_DELIVERY_TICKET_FAILURE,
        });
      });
  };
};

export const checkOutRental = (id, onSuccess = () => {}) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Checking out order inventory...'));
    dispatch({
      type: CHECK_OUT_RENTAL_REQUEST,
    });

    const {
      rental: {
        checkOutSignature,
        checkOutSignerName,
        checkOutSignerTitle,
        checkOutSignerCompany,
        businessCheckoutSignature,
        businessCheckoutSignerName,
        businessCheckoutSignerTitle,
        businessCheckoutSignerCompany,
      },
    } = getState().rental;
    const data = {
      rental: {
        checkout_signature: checkOutSignature,
        checkout_signer_name: checkOutSignerName,
        checkout_signer_title: checkOutSignerTitle,
        checkout_signer_company: checkOutSignerCompany,
        business_checkout_signature: businessCheckoutSignature,
        business_checkout_signer_name: businessCheckoutSignerName,
        business_checkout_signer_title: businessCheckoutSignerTitle,
        business_checkout_signer_company: businessCheckoutSignerCompany,
      },
    };

    Endpoints.rentals
      .checkOutOne(id, data)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: CHECK_OUT_RENTAL_SUCCESS,
          payload: {
            rental: response.data.rental,
          },
        });
        dispatch(fetchRentalPickList(response.data.rental.token));
        const link = `/orders/rentals/${id}/pickList`;
        dispatch(openSuccessSnackBar('Inventory successfully checked out.'));
        history.push(link);
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error?.response?.data));
        dispatch({
          type: CHECK_OUT_RENTAL_FAILURE,
        });
      });
  };
};

export const checkOutDeliveryTicket = (
  rentalId,
  deliveryTicket,
  onSuccess = () => {}
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Checking out secondary order inventory...'));
    dispatch({
      type: CHECKOUT_DELIVERY_TICKET_REQUEST,
    });
    const {
      checkOutSignature,
      checkOutSignerName,
      checkOutSignerTitle,
      checkOutSignerCompany,
      businessCheckoutSignature,
      businessCheckoutSignerName,
      businessCheckoutSignerTitle,
      businessCheckoutSignerCompany,
    } = deliveryTicket;

    const data = {
      delivery_ticket: {
        checkout_signature: checkOutSignature,
        checkout_signer_name: checkOutSignerName,
        checkout_signer_title: checkOutSignerTitle,
        checkout_signer_company: checkOutSignerCompany,
        business_checkout_signature: businessCheckoutSignature,
        business_checkout_signer_name: businessCheckoutSignerName,
        business_checkout_signer_title: businessCheckoutSignerTitle,
        business_checkout_signer_company: businessCheckoutSignerCompany,
      },
    };

    service
      .post(
        `${ROOT_URL}/rentals/${rentalId}/delivery_tickets/${deliveryTicket.id}/check_out`,
        data
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          response: response.data,
          type: CHECKOUT_DELIVERY_TICKET_SUCCESS,
        });

        const rentalToken = response.data.deliveryTickets.find(
          (dt) => deliveryTicket.id === dt.id
        )?.rental?.token;

        dispatch(fetchRentalPickList(rentalToken));

        const link = `/orders/rentals/${rentalId}/deliveryTickets/${deliveryTicket.id}/pickList`;
        dispatch(
          openSuccessSnackBar(
            'Secondary order inventory successfully checked out.'
          )
        );
        history.push(link);
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response));
        dispatch({
          type: CHECKOUT_DELIVERY_TICKET_FAILURE,
        });
      });
  };
};

export const convertToInProgress = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Checking out order inventory...'));
    dispatch({
      type: UPDATE_RENTAL_REQUEST,
    });

    Endpoints.rentals
      .convertOneToInProgress(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
        dispatch(openSuccessSnackBar('Order is now in progress.'));
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
        });
      });
  };
};

export const updateRental = (
  id,
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Resetting Expired Rental...'));
    dispatch({
      type: UPDATE_RENTAL_REQUEST,
    });

    Endpoints.rentals
      .updateOne(id, data)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
        onSuccess(response);
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
        });
        onError(error);
      });
  };
};

export const repositionRentalInventory = (
  id,
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_RENTAL_REQUEST,
    });

    Endpoints.rentals
      .repositionRentalInventory(id, data)
      .then((response) => {
        dispatch({
          type: REPOSITION_INVENTORY_SUCCESS,
          response,
        });
        onSuccess(response);
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
        });
        onError(error);
      });
  };
};

export const submitRentalSignature = (
  id,
  data,
  onSuccess = () => {},
  onError = () => {}
) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_RENTAL_REQUEST,
    });

    Endpoints.rentals
      .submitSignature(id, data)
      .then((response) => {
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
        onSuccess(response);
      })
      .catch((error) => {
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
        });
        onError(error);
      });
  };
};

export const checkInRental = (id, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Checking in order inventory...'));
    dispatch({
      type: CHECK_IN_RENTAL_REQUEST,
    });

    const {
      rental: {
        checkInSignature,
        checkInSignerName,
        checkInSignerTitle,
        checkInSignerCompany,
        businessCheckinSignature,
        businessCheckinSignerName,
        businessCheckinSignerTitle,
        businessCheckinSignerCompany,
      },
    } = getState().rental;
    const data = {
      rental: {
        checkin_signature: checkInSignature,
        checkin_signer_name: checkInSignerName,
        checkin_signer_title: checkInSignerTitle,
        checkin_signer_company: checkInSignerCompany,
        business_checkin_signature: businessCheckinSignature,
        business_checkin_signer_name: businessCheckinSignerName,
        business_checkin_signer_title: businessCheckinSignerTitle,
        business_checkin_signer_company: businessCheckinSignerCompany,
      },
    };

    Endpoints.rentals
      .checkInOne(id, data)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: CHECK_IN_RENTAL_SUCCESS,
          response,
        });
        dispatch(fetchRentalPickList(response.data.rental.token));
        const link = `/orders/rentals/${id}/pickList`;
        dispatch(openSuccessSnackBar('Inventory successfully checked in.'));
        onSuccess();
        history.push(link);
      })
      .catch((error) => {
        onError();
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: CHECK_IN_RENTAL_FAILURE,
        });
      });
  };
};

export const checkInDeliveryTicket = (
  rentalId,
  deliveryTicket,
  onSuccess = () => {},
  onError = () => {}
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Checking in secondary order inventory...'));
    dispatch({
      type: CHECKIN_DELIVERY_TICKET_REQUEST,
    });

    const {
      checkInSignature,
      checkInSignerName,
      checkInSignerTitle,
      checkInSignerCompany,
      businessCheckinSignature,
      businessCheckinSignerName,
      businessCheckinSignerTitle,
      businessCheckinSignerCompany,
    } = deliveryTicket;

    const data = {
      delivery_ticket: {
        checkin_signature: checkInSignature,
        checkin_signer_name: checkInSignerName,
        checkin_signer_title: checkInSignerTitle,
        checkin_signer_company: checkInSignerCompany,
        business_checkin_signature: businessCheckinSignature,
        business_checkin_signer_name: businessCheckinSignerName,
        business_checkin_signer_title: businessCheckinSignerTitle,
        business_checkin_signer_company: businessCheckinSignerCompany,
      },
    };

    service
      .post(
        `${ROOT_URL}/rentals/${rentalId}/delivery_tickets/${deliveryTicket.id}/check_in`,
        data
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          response: response.data,
          type: CHECKIN_DELIVERY_TICKET_SUCCESS,
        });

        const rentalToken = response.data.deliveryTickets.find(
          (dt) => deliveryTicket.id === dt.id
        )?.rental?.token;

        dispatch(fetchRentalPickList(rentalToken));

        const link = `/orders/rentals/${rentalId}/deliveryTickets/${deliveryTicket.id}/pickList`;
        dispatch(
          openSuccessSnackBar(
            'Secondary order inventory successfully checked in.'
          )
        );
        onSuccess();
        history.push(link);
      })
      .catch((error) => {
        onError();
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: CHECKIN_DELIVERY_TICKET_FAILURE,
        });
      });
  };
};

export const requestRentalDepositSignature = (
  id,
  requestDeposit,
  requestSignature
) => {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_RENTAL_REQUEST,
    });
    dispatch(openLoadingSpinner('Please wait...'));

    const data = {
      request_deposit: requestDeposit,
      request_signature: requestSignature,
    };
    Endpoints.rentals
      .requestRequirements(id, data)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: REQUEST_RENTAL_SUCCESS,
          response,
        });
        if (requestDeposit && requestSignature) {
          dispatch(openSuccessSnackBar('Requested Deposit and Signature'));
        } else if (requestDeposit) {
          dispatch(openSuccessSnackBar('Requested Deposit'));
        } else if (requestSignature) {
          dispatch(openSuccessSnackBar('Requested Signature'));
        }
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(setErrors(error.response.data));
        dispatch(closeLoadingSpinner());
        dispatch({
          type: REQUEST_RENTAL_FAILURE,
          error,
        });
      });
  };
};

export const downloadInvoice = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Generating invoice document...'));

    Endpoints.rentals
      .generateInvoice(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        window.open(response.data.invoice.url, '_blank');
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
      });
  };
};

export const bulkPickRentalItems = (
  rentalId,
  rentalItemIds,
  type = 'items',
  status
) => {
  return (dispatch, getState) => {
    if (rentalItemIds.length === 0) {
      return;
    }
    dispatch(openLoadingSpinner(''));
    dispatch({
      type: BULK_PICK_RENTAL_ITEMS_REQUEST,
    });
    Endpoints.rentals.rentalItems
      .bulkPick(rentalId, rentalItemIds, type, status)
      .then((response) => {
        dispatch({
          type: BULK_PICK_RENTAL_ITEMS_SUCCESS,
          payload: {
            rental: response.data.rental,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(rentalId));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: BULK_PICK_RENTAL_ITEMS_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const serializedBulkPickRentalItems = (
  rentalId,
  body,
  type = 'items'
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner(''));
    dispatch({
      type: SERIALIZED_BULK_PICK_RENTAL_ITEMS_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .serializedBulkPick(rentalId, body)
      .then((response) => {
        dispatch({
          type: SERIALIZED_BULK_PICK_RENTAL_ITEMS_SUCCESS,
          payload: {
            rental: response.data.rental,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(rentalId));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: SERIALIZED_BULK_PICK_RENTAL_ITEMS_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const pickRentalItem = (
  id,
  itemId,
  pickedQuantity,
  type = 'items',
  status = 'reservation'
) => {
  return (dispatch, getState) => {
    dispatch({
      type: PICK_RENTAL_ITEM_REQUEST,
    });
    dispatch(openLoadingSpinner(''));
    Endpoints.rentals.rentalItems
      .pickOne(id, itemId, pickedQuantity, type, status)
      .then((response) => {
        dispatch({
          type: PICK_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(id));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: PICK_RENTAL_ITEM_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const serializedPickRentalItem = (
  id,
  itemId,
  pickedQuantity,
  pickedIds,
  type = 'items'
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner(''));
    dispatch({
      type: PICK_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .serializedPick(id, itemId, pickedQuantity, pickedIds, type)
      .then((response) => {
        dispatch({
          type: PICK_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(id));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          dispatch(setErrors(error.response.data));
        }
        dispatch({
          type: PICK_RENTAL_ITEM_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const serializedPickRentalItemWithUsage = (
  id,
  itemId,
  pickedQuantity,
  pickedIds,
  usageValues,
  type = 'items',
  status = 'reservation'
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner(''));
    dispatch({
      type: PICK_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .serializedPickWithUsage(
        id,
        itemId,
        pickedQuantity,
        pickedIds,
        type,
        usageValues,
        status
      )
      .then((response) => {
        dispatch({
          type: PICK_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(id));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          dispatch(setErrors(error.response.data));
        }
        dispatch({
          type: PICK_RENTAL_ITEM_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const serializedMultiplePickRentalItem = (
  id,
  itemId,
  pickedQuantity,
  pickedId,
  type = 'items'
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner(''));
    dispatch({
      type: PICK_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .serializedMultiplePick(id, itemId, pickedQuantity, pickedId, type)
      .then((response) => {
        dispatch({
          type: PICK_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(id));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          dispatch(setErrors(error.response.data));
        }
        dispatch({
          type: PICK_RENTAL_ITEM_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const unpickRentalItem = (id, itemId, type = 'items') => {
  return (dispatch, getState) => {
    dispatch({
      type: UNPICK_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .unpickOne(id, itemId, type)
      .then((response) => {
        dispatch({
          type: UNPICK_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: UNPICK_RENTAL_ITEM_FAILURE,
          error,
        });
      });
  };
};

export const bulkCheckInRentalItems = (
  rentalId,
  rentalItemIds,
  type = 'items',
  status
) => {
  return (dispatch, getState) => {
    if (rentalItemIds.length === 0) {
      return;
    }
    dispatch({
      type: BULK_CHECK_IN_RENTAL_ITEMS_REQUEST,
    });
    dispatch(openLoadingSpinner(''));
    Endpoints.rentals.rentalItems
      .bulkCheckIn(rentalId, rentalItemIds, type, status)
      .then((response) => {
        dispatch({
          type: BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS,
          payload: {
            rental: response.data.rental,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(rentalId));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: BULK_CHECK_IN_RENTAL_ITEMS_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const serializedBulkCheckInRentalItems = (
  rentalId,
  body,
  type = 'items'
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner(''));
    dispatch({
      type: SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .serializedBulkCheckIn(rentalId, body)
      .then((response) => {
        dispatch({
          type: SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS,
          payload: {
            rental: response.data.rental,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(rentalId));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const checkInRentalItem = (
  id,
  itemId,
  checkedInQuantity,
  type = 'items',
  status
) => {
  return (dispatch, getState) => {
    dispatch({
      type: CHECK_IN_RENTAL_ITEM_REQUEST,
    });
    dispatch(openLoadingSpinner(''));
    Endpoints.rentals.rentalItems
      .checkIn(id, itemId, checkedInQuantity, type, status)
      .then((response) => {
        dispatch({
          type: CHECK_IN_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(id));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: CHECK_IN_RENTAL_ITEM_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const serializedCheckInRentalItem = (
  id,
  itemId,
  checkedInQuantity,
  checkedInIds,
  type = 'items'
) => {
  return (dispatch, getState) => {
    dispatch({
      type: CHECK_IN_RENTAL_ITEM_REQUEST,
    });
    dispatch(openLoadingSpinner(''));
    Endpoints.rentals.rentalItems
      .serializedCheckIn(id, itemId, checkedInQuantity, checkedInIds, type)
      .then((response) => {
        dispatch({
          type: CHECK_IN_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(id));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: CHECK_IN_RENTAL_ITEM_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const serializedUniqueCheckInRentalItem = (
  id,
  itemId,
  checkedInQuantity,
  checkedInIds,
  usageValues,
  type = 'items'
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner(''));
    dispatch({
      type: CHECK_IN_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .serializedUniqueCheckIn(
        id,
        itemId,
        checkedInQuantity,
        checkedInIds,
        usageValues,
        type
      )
      .then((response) => {
        dispatch({
          type: CHECK_IN_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
        dispatch(fetchDeliveryTickets(id));
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: CHECK_IN_RENTAL_ITEM_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const serializedMultipleCheckInRentalItem = (
  id,
  itemId,
  checkedInQuantity,
  checkedInId,
  type = 'items'
) => {
  return (dispatch, getState) => {
    dispatch({
      type: CHECK_IN_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .serializedMultipleCheckIn(
        id,
        itemId,
        checkedInQuantity,
        checkedInId,
        type
      )
      .then((response) => {
        dispatch({
          type: CHECK_IN_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: CHECK_IN_RENTAL_ITEM_FAILURE,
          error,
        });
      });
  };
};

export const bulkUncheckInRentalItems = (
  rentalId,
  rentalItemIds,
  type = 'items'
) => {
  return (dispatch, getState) => {
    if (rentalItemIds.length === 0) {
      return;
    }
    dispatch({
      type: BULK_UNCHECK_IN_RENTAL_ITEMS_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .bulkUncheckIn(rentalId, rentalItemIds, type)
      .then((response) => {
        dispatch({
          type: BULK_UNCHECK_IN_RENTAL_ITEMS_SUCCESS,
          payload: {
            rental: response.data.rental,
            inventoryType: type,
          },
        });
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: BULK_UNCHECK_IN_RENTAL_ITEMS_FAILURE,
          error,
        });
      });
  };
};

export const uncheckInRentalItem = (id, itemId, type = 'items') => {
  return (dispatch, getState) => {
    dispatch({
      type: UNCHECK_IN_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .uncheckIn(id, itemId, type)
      .then((response) => {
        dispatch({
          type: CHECK_IN_RENTAL_ITEM_SUCCESS,
          payload: {
            rental: response.data,
            inventoryType: type,
          },
        });
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: UNCHECK_IN_RENTAL_ITEM_FAILURE,
          error,
        });
      });
  };
};

export const reportLackRentalItem = (
  id,
  item,
  checkInData,
  lackNotes,
  type = 'items',
  onSuccess = () => {}
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner(''));
    dispatch({
      type: REPORT_LACK_RENTAL_ITEM_REQUEST,
    });
    const inventoryProduct = item[apiToSingularConnections[type]];
    const { damaged, checkedInQuantity, missingQuantity } = checkInData;
    const data = Object.assign(
      {
        missing_quantity:
          missingQuantity ?? item.pickedQuantity - checkedInQuantity - damaged,
        damaged_quantity: damaged,
        checked_in_quantity: checkedInQuantity,
      },
      Number(damaged) > 0 &&
        inventoryProduct && {
          maintenance_record_attributes: {
            quantity: damaged,
            maintainable_type: apiToClassNames[type],
            maintainable_id: inventoryProduct['id'],
            lack_notes: lackNotes,
          },
        }
    );

    Endpoints.rentals.rentalItems
      .updateOne(id, item.id, data, true, type)
      .then((response) => {
        onSuccess();
        dispatch({
          type: REPORT_LACK_RENTAL_ITEM_SUCCESS,
          response,
        });
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        dispatch(setErrors(error.response.data));
        dispatch({
          type: REPORT_LACK_RENTAL_ITEM_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const addFeeRentalItem = (
  id,
  itemId,
  data,
  type = 'items',
  onSuccess = () => {}
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner(''));
    dispatch({
      type: ADD_FEE_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .updateOne(id, itemId, data, true, type)
      .then((response) => {
        onSuccess();
        dispatch({
          type: ADD_FEE_RENTAL_ITEM_SUCCESS,
          response,
        });
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: ADD_FEE_RENTAL_ITEM_FAILURE,
          error,
        });
        dispatch(closeLoadingSpinner());
      });
  };
};

export const updateRentalItem = (
  id,
  itemId,
  data,
  type = 'items',
  onSuccess = () => {}
) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .updateOne(id, itemId, data, false, type, 'pick_list')
      .then((response) => {
        dispatch(closeLoadingSpinner());
        onSuccess();
        dispatch({
          type: UPDATE_RENTAL_ITEM_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          type: UPDATE_RENTAL_ITEM_FAILURE,
          error,
        });
      });
  };
};

export const deleteSubRental = (
  id,
  itemId,
  data,
  type = 'items',
  onSuccess = () => {}
) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_RENTAL_ITEM_REQUEST,
    });

    Endpoints.rentals.rentalItems
      .updateOne(id, itemId, data, false, type, 'rentals')
      .then((response) => {
        dispatch(closeLoadingSpinner());
        onSuccess();
        dispatch({
          type: UPDATE_RENTAL_ITEM_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          type: UPDATE_RENTAL_ITEM_FAILURE,
          error,
        });
      });
  };
};

export const setSubrentalItem = (subrentalItemId) => ({
  type: SET_SUBRENTAL_ITEM,
  payload: {
    subrentalItemId,
  },
});

export const setCheckInSignature = (
  signature,
  signerName,
  signerTitle,
  signerCompany
) => ({
  type: SET_CHECK_IN_SIGNATURE,
  payload: {
    signature,
    signerName,
    signerTitle,
    signerCompany,
  },
});

export const setCheckOutSignature = (
  signature,
  signerName,
  signerTitle,
  signerCompany
) => ({
  type: SET_CHECK_OUT_SIGNATURE,
  payload: {
    signature,
    signerName,
    signerTitle,
    signerCompany,
  },
});

export const setRentalProps = (newProps) => ({
  type: SET_RENTAL_PROPS,
  payload: {
    ...newProps,
  },
});

export const connectRental = (
  id,
  itemId,
  productId,
  productClass,
  quantity,
  breakpoint = '',
  onSuccess = () => {}
) => {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECT_RENTAL_REQUEST,
    });
    const data = {
      rental_item_id: itemId,
      product_id: productId,
      product_class: productClass,
      quantity: quantity,
      breakpoint: breakpoint,
    };

    Endpoints.rentals.connect
      .connectRental(id, data)
      .then((response) => {
        onSuccess();
        dispatch({
          type: CONNECT_RENTAL_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: CONNECT_RENTAL_FAILURE,
          error,
        });
      });
  };
};

export const updateSupplierRental = (
  id,
  supplierRentalId,
  data,
  onSuccess,
  onError
) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_SUPPLIER_RENTAL_REQUEST,
    });

    Endpoints.rentals.supplierRentals
      .updateOne(id, supplierRentalId, data)
      .then((response) => {
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
        onSuccess(response);
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
        });
        onError(error);
      });
  };
};

export const submitQuote = (id, supplierRentalId) => {
  return (dispatch, getState) => {
    dispatch({
      type: SUBMIT_QUOTE_REQUEST,
    });

    Endpoints.rentals.supplierRentals
      .submitQuote(id, supplierRentalId)
      .then((response) => {
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          error,
        });
      });
  };
};

export const requestChanges = (
  id,
  supplierRentalId,
  data,
  onSuccess,
  onError
) => {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_CHANGES_REQUEST,
    });

    Endpoints.rentals.supplierRentals
      .requestChanges(id, supplierRentalId, data)
      .then((response) => {
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
        onSuccess(response);
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          error,
        });
        onError(error);
      });
  };
};

export const requestCancel = (id, supplierRentalId, onSuccess, onError) => {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_CANCEL_REQUEST,
    });

    Endpoints.rentals.supplierRentals
      .requestCancel(id, supplierRentalId)
      .then((response) => {
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
        onSuccess(response);
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          error,
        });
        onError(error);
      });
  };
};

export const approveQuote = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Approving Quote...'));

    Endpoints.rentals.connect
      .approve(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,

          response,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          error,
        });
      });
  };
};

export const denyQuote = (id, reason) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Declining Quote...'));

    Endpoints.rentals.connect
      .deny(id, reason)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          error,
        });
      });
  };
};

export const approveChangeRequest = (id, onSuccess) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Approving Change Request...'));

    Endpoints.rentals.connect
      .approveChangeRequest(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          error,
        });
      });
  };
};

export const denyChangeRequest = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Declining Change Request...'));

    Endpoints.rentals.connect
      .denyChangeRequest(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          error,
          type: UPDATE_RENTAL_FAILURE,
        });
      });
  };
};

export const approveCancelRequest = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Approving Cancel Request...'));

    Endpoints.rentals.connect
      .approveCancelRequest(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          error,
        });
      });
  };
};
export const denyCancelRequest = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Declining Cancel Request...'));

    Endpoints.rentals.connect
      .denyCancelRequest(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          error,
        });
      });
  };
};

export const ignoreExpiredDocument = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Ignoring Expired Document Request...'));

    Endpoints.rentals
      .ignoreExpiredDocument(id)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: FETCH_RENTAL_INFO_SUCCESS,
          infoName: 'header_info',
          response,
        });
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          error,
        });
      });
  };
};

export const addRentalUnreadMessage = (payload) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    dispatch({
      type: ADD_RENTAL_UNREAD_MESSAGE,
      payload,
      currentUser: user,
    });
  };
};

export const setRentalFromResponse = (response) => {
  return {
    type: FETCH_RENTAL_SUCCESS,
    response,
  };
};

export function setManipulatedPickListItems(payload) {
  return {
    type: SET_MANIPULATED_PICK_LIST_ITEMS,
    payload: payload,
  };
}

export const fetchDeliveryTickets = (rentalToken) => {
  return (dispatch, getState) => {
    if (
      getState().rental &&
      getState().rental.rental.rentalSetting.multipleDeliveryTickets == false
    ) {
      return;
    }
    dispatch(openLoadingSpinner('Fetching secondary orders...'));

    return service
      .get(`${ROOT_URL}/rentals/${rentalToken}/delivery_tickets`)
      .then((response) => {
        dispatch({
          response: response.data,
          type: FETCH_DELIVERY_TICKETS_REQUEST_SUCCESS,
        });
        dispatch(closeLoadingSpinner());
        return response;
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_DELIVERY_TICKETS_REQUEST_FAILURE,
        });
      });
  };
};

export const setSelectedDeliveryTicketId = (selectedDeliveryTicketId) => ({
  type: SET_SELECTED_DT_ID,
  selectedDeliveryTicketId,
});

export const fetchDeliveryTicketsUnassignedPickList = (rentalToken) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Fetching unassigned items...'));
    return service
      .get(
        `${ROOT_URL}/rentals/${rentalToken}/delivery_tickets/unassigned_picklist_items`
      )
      .then((response) => {
        const convertedData = converter.snakeToCamelCase(response.data);

        dispatch({
          response: convertedData,
          type: FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_SUCCESS,
        });
        dispatch(closeLoadingSpinner());
        return response;
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_FAILURE,
        });
      });
  };
};

export const assignItemsToDeliveryTicket = (
  items,
  deliveryTicketId,
  rentalToken
) => {
  //Payload Sample
  //  data = {
  //   pick_list_items_ids: [1,3,4,5,6],
  //   pick_list_accessories_ids: [1,3,4]
  //   pick_list_add_ons_ids: [3,4,5]
  //
  //  }

  const data = {
    pick_list_items_ids: [],
    pick_list_accessories_ids: [],
    pick_list_add_ons_ids: [],
  };

  items.forEach((item) => {
    if (
      item.hasOwnProperty('productId') ||
      item.type === 'PickListItemTemporary'
    ) {
      data.pick_list_items_ids.push(item.id);
    } else if (item.hasOwnProperty('accessoryId')) {
      data.pick_list_accessories_ids.push(item.id);
    } else if (item.hasOwnProperty('addOnId')) {
      data.pick_list_add_ons_ids.push(item.id);
    } else {
      console.error('Not able to identify the type of item: ', item);
      return;
    }
  });

  return (dispatch, getState) => {
    if (
      data.pick_list_items_ids.length > 0 ||
      data.pick_list_accessories_ids.length > 0 ||
      data.pick_list_add_ons_ids.length > 0
    ) {
      dispatch(openLoadingSpinner('Assigning item(s) to secondary order...'));
      return service
        .post(
          `${ROOT_URL}/rentals/${rentalToken}/delivery_tickets/${deliveryTicketId}/assign`,
          data
        )
        .then((response) => {
          dispatch(fetchDeliveryTicketsUnassignedPickList(rentalToken));
          dispatch(fetchDeliveryTickets(rentalToken));
          dispatch(
            openSuccessSnackBar(
              'Item is successfully assigned to secondary order.'
            )
          );
        })
        .catch((error) => {
          dispatch(closeLoadingSpinner());
          saveError(error, getState());
        });
    } else {
      dispatch(
        setErrors('Ooooppp! Something went wrong. Please contact to support.')
      );
      return;
    }
  };
};

export const fetchDeliveryTicket = (rentalToken, deliveryTicketId) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Fetching secondary order details...'));
    service
      .get(
        `${ROOT_URL}/rentals/${rentalToken}/delivery_tickets/${deliveryTicketId}`
      )
      .then((response) => {
        dispatch({
          response: response.data,
          type: SET_SELECTED_DELIVERY_TICKET_SUCCESS,
        });
        dispatch(closeLoadingSpinner());
        dispatch(fetchDeliveryTicketsUnassignedPickList(rentalToken));
        dispatch(setSelectedDeliveryTicketId(deliveryTicketId));
      })
      .catch((error) => {
        saveError(error);
        dispatch(closeLoadingSpinner());
        dispatch({
          type: SET_SELECTED_DELIVERY_TICKET_FAILURE,
        });
      });
  };
};

export const unAssignItemsToDeliveryTicket = (
  items,
  deliveryTicketId,
  rentalToken
) => {
  //Payload Sample
  //  data = {
  //   pick_list_items_ids: [1,3,4,5,6],
  //   pick_list_accessories_ids: [1,3,4]
  //   pick_list_add_ons_ids: [3,4,5]
  //
  //  }

  const data = {
    pick_list_items_ids: [],
    pick_list_accessories_ids: [],
    pick_list_add_ons_ids: [],
  };

  items.forEach((item) => {
    if (
      item.hasOwnProperty('productId') ||
      item.type === 'RentalItemTemporary'
    ) {
      data.pick_list_items_ids.push(item.id);
    } else if (item.hasOwnProperty('accessoryId')) {
      data.pick_list_accessories_ids.push(item.id);
    } else if (item.hasOwnProperty('addOnId')) {
      data.pick_list_add_ons_ids.push(item.id);
    } else {
      console.error('Not able to identify the type of item: ', item);
      return;
    }
  });

  return (dispatch, getState) => {
    if (
      data.pick_list_items_ids.length > 0 ||
      data.pick_list_accessories_ids.length > 0 ||
      data.pick_list_add_ons_ids.length > 0
    ) {
      dispatch(openLoadingSpinner('Unassigning item(s) to secondary order...'));
      return service
        .post(
          `${ROOT_URL}/rentals/${rentalToken}/delivery_tickets/${deliveryTicketId}/unassign`,
          data
        )
        .then((response) => {
          dispatch(fetchDeliveryTickets(rentalToken));
          dispatch(fetchDeliveryTicket(rentalToken, deliveryTicketId));
          dispatch(
            openSuccessSnackBar(
              'Item is successfully unassigned to secondary order.'
            )
          );
        })
        .catch((error) => {
          dispatch(closeLoadingSpinner());
          saveError(error, getState());
        });
    } else {
      dispatch(
        setErrors('Ooooppp! Something went wrong. Please contact to support.')
      );
      return;
    }
  };
};

export const destroyDeliveryTicket = (rentalToken, deliveryTicketId) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Destroying secondary order...'));

    service
      .delete(
        `${ROOT_URL}/rentals/${rentalToken}/delivery_tickets/${deliveryTicketId}`
      )
      .then((response) => {
        dispatch(
          openSuccessSnackBar('Successfully deleted the secondary order.')
        );
        dispatch(closeLoadingSpinner());
        dispatch(fetchDeliveryTickets(rentalToken));
        dispatch(fetchDeliveryTicketsUnassignedPickList(rentalToken));
      })
      .catch((error) => {
        dispatch(setErrors(error.response.data));
        dispatch(closeLoadingSpinner());
      });
  };
};

export const createRentalServiceRoutes = (rental_id) => {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_RENTAL_SERVICE_ROUTES_REQUEST });

    const route = `${ROOT_URL}/rentals/${rental_id}/create_service_routes`;

    dispatch(openLoadingSpinner('Setting service routes...'));

    return service
      .post(route)
      .then((response) => {
        dispatch({
          response: response.data,
          type: CREATE_RENTAL_SERVICE_ROUTES_SUCCESS,
        });

        dispatch(closeLoadingSpinner());

        dispatch(
          openSuccessSnackBar('Service routes were created successfully.', 5)
        );
      })
      .catch((error) => {
        saveError(error, getState());

        dispatch(setErrors(error.response.data));

        dispatch(closeLoadingSpinner());

        dispatch({
          error,
          type: CREATE_RENTAL_SERVICE_ROUTES_FAILURE,
        });
      });
  };
};
